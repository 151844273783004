<template>
  <div class="card flex justify-content-center">
    <Sidebar v-model:visible="toggle" position="right" class="w-full max-w-md relative">
      <template #container>
        <div class="p-4 h-full overflow-auto flex flex-col gap-6">
          <h2 class="text-lg font-bold">WorknProsper Salary Advance</h2>

          <div class="absolute top-4 right-4 bg-newGrey3 w-7 h-7 rounded-lg flex items-center justify-center">
            <i @click="emit('update:visible', false)" class="cursor-pointer pi pi-times text-sm" />
          </div>
          <div class="p-3 text-xs sm:text-sm bg-newGrey3 rounded-xl">
            Empower your workforce with financial freedom like never before with the Salary Advance feature. Now, you
            can easily offer advances to your employees via the WorknProsper app.
          </div>
          <div class="p-3 text-xs sm:text-sm bg-newGrey3 rounded-xl">
            <strong>How it Works</strong>
            <p>1. Add your Employee(s)</p>
            <p>2. Specify the duration for which the advance will be available to the employees.</p>
            <p>3. Your employees can effortlessly withdraw the advance from their WorknProsper account</p>
          </div>
        </div>
      </template>
    </Sidebar>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:visible"]);

const toggle = computed({
  get() {
    return props.visible;
  },
  set(val) {
    emit("update:visible", false);
  },
});
</script>

<style></style>
